import { Injectable } from '@angular/core';
import { compose, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { addDays, parse } from 'date-fns';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import sortBy from 'lodash-es/sortBy';
import uniqueId from 'lodash-es/uniqueId';
import { createSelector } from 'reselect';
import { Observable, of as observableOf, throwError as observableThrowError, of, switchMap } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import u from 'updeep';

import { hexToRGB, isColorDark } from '../../../shared/contrast.helper';
import { AppState } from '../../index';
import { getArrayFromActionPayload, mapAndSortEntities, mapEntity, PayloadKeyType } from '../../shared/entity.helper';
import { undo } from '../../shared/undo/undoAction';
import { getDepartmentEntities } from '../department/department.service';
import { getLocationEntities } from '../location/location.service';
import { getTeamEntities } from '../team/team.service';
import { EventAction } from './event.action';
import { EventApi, EventsLoadRequest } from './event.api';
import { EnhancedEventModel, EventModel, EventType } from './event.model';

@Injectable()
export class EventService {
  public constructor(
    private store: Store<AppState>,
    private api: EventApi,
    private translate: TranslateService,
  ) {}

  public eventTypes: Record<EventType, string> = {
    [EventType.OCCURRENCE]: this.translate.instant('Only this event'),
    [EventType.SEQUENCE]: this.translate.instant('All future events'),
  };

  public load(requestData: EventsLoadRequest, updateStore = true) {
    return this.api.load(requestData, EventAction.load(requestData)).pipe(
      map((response) => {
        if (updateStore) {
          this.store.dispatch(EventAction.loadSuccess(response));
        }

        return response;
      }),
      catchError((response) => {
        this.store.dispatch(EventAction.loadFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public add(eventData): Observable<any> {
    return this.api.add(eventData, EventAction.add(eventData)).pipe(
      map((response) => {
        this.store.dispatch(EventAction.addSuccess(response));
        return observableOf(response);
      }),
      catchError((response) => {
        this.store.dispatch(EventAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public addSequence(eventData): Observable<any> {
    return this.api.addSequence(eventData, EventAction.add(eventData)).pipe(
      switchMap(({ sequenceId }) => {
        if (!sequenceId) {
          return of(null);
        }
        return this.fetchSequence(sequenceId);
      }),
      catchError((response) => {
        this.store.dispatch(EventAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public update(id, eventData) {
    const loadingId = uniqueId('loading_');

    const action = EventAction.update(eventData, id, loadingId);

    return this.api.update(id, eventData, action).pipe(
      tap((response) => {
        this.store.dispatch(EventAction.updateSuccess(response, loadingId));
      }),
      catchError((response) => {
        this.store.dispatch(undo(action));

        return observableThrowError(response);
      }),
    );
  }

  public updateSequence(id, eventData) {
    const loadingId = uniqueId('loading_');

    const action = EventAction.update(eventData, id, loadingId);

    return this.api.updateSequence(id, eventData, action).pipe(
      switchMap((response) => {
        const event = getArrayFromActionPayload(PayloadKeyType.EVENTS, response?.entities)[0];
        if (!event?.sequence_id) {
          return of(null);
        }
        return this.fetchSequence(event.sequence_id);
      }),
      tap((response) => {
        this.store.dispatch(EventAction.updateSuccess(response, loadingId));
      }),
      catchError((response) => {
        this.store.dispatch(undo(action));
        return observableThrowError(response);
      }),
    );
  }

  public fetch(id) {
    return this.api.fetch(id, EventAction.fetch(id)).pipe(
      tap((response) => {
        this.store.dispatch(EventAction.fetchSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(EventAction.fetchFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  private fetchSequence(id) {
    return this.api.fetchSequence(id, EventAction.fetch(id)).pipe(
      tap((response) => {
        this.store.dispatch(EventAction.addSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(EventAction.addFailed(response));
        return observableThrowError(response);
      }),
    );
  }

  public remove(id: string, departmentId: string) {
    return this.api.remove(id, EventAction.remove(id)).pipe(
      tap(() => {
        this.store.dispatch(EventAction.removeSuccess(id, departmentId, false));
      }),
      catchError((response) => {
        this.store.dispatch(EventAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public removeSequence(id: string, departmentId: string) {
    return this.api.removeSequence(id, EventAction.remove(id)).pipe(
      tap(() => {
        this.store.dispatch(EventAction.removeSuccess(id, departmentId, true));
      }),
      catchError((response) => {
        this.store.dispatch(EventAction.removeFailed(id, response));
        return observableThrowError(response);
      }),
    );
  }

  public save(eventData) {
    if (eventData.id) {
      return this.update(eventData.id, eventData);
    }

    eventData = u.omit('id', eventData);

    return this.add(eventData);
  }
}

export const sortEvents = (events: EventModel[]) => sortBy(events, ['date', 'starttime']);
export const mapAndSortEvents = mapAndSortEntities(sortEvents);

export const getEventState = (state: AppState) => state.orm.events;

export const getEventIds = compose((state) => state.items, getEventState);

export const getEventEntities = createSelector(getEventState, getTeamEntities, (state, teamEntities) =>
  mapValues(state.itemsById, (event) => {
    const color = event.team_id && teamEntities[event.team_id] ? teamEntities[event.team_id].color : '#fbe32e';

    const color_rgb = hexToRGB(color);
    const color_is_dark = isColorDark(color);

    const starttime = event.starttime || '00:00:00';
    const endtime = event.endtime || '00:00:00';

    const startDateTime = parse(event.date + ' ' + starttime, 'yyyy-MM-dd HH:mm:ss', new Date());
    let endDateTime = parse(event.date + ' ' + endtime, 'yyyy-MM-dd HH:mm:ss', new Date());

    if (endDateTime <= startDateTime) {
      endDateTime = addDays(endDateTime, 1);
    }

    return {
      ...event,
      color,
      color_is_dark,
      color_rgb,
      startDateTime,
      endDateTime,
    };
  }),
);

export const getEvent = (id) => createSelector(getEventEntities, (entities) => mapEntity(id, entities));

export const getEvents = createSelector(getEventIds, getEventEntities, mapAndSortEvents);

export const getEnhancedEvents = createSelector(
  getEvents,
  getTeamEntities,
  getDepartmentEntities,
  getLocationEntities,
  (events, teams, departments, locations): EnhancedEventModel[] =>
    events.map((event: EventModel) => {
      const department = departments[event.department_id];
      const location = locations[department?.location_id];
      const team = teams[event.team_id];

      return {
        ...event,
        department,
        location,
        team,
      };
    }),
);

export const getEnhancedEvent = (id) =>
  createSelector(getEnhancedEvents, (events): EnhancedEventModel => events.find((event) => event.id === id));

export const groupEventsByDepartment = (events) => groupBy(events, 'department_id');
