import { DepartmentModel } from '../department/department.model';
import { LocationModel } from '../location/location.model';
import { SharedModelState } from '../shared.model.state';
import { TeamModel } from '../team/team.model';

export interface EventState extends SharedModelState<EventModel> {}

export interface EventMap {
  [id: string]: EventModel;
}

export interface EventModel {
  id: string;
  account_id: string;
  department_id: string;
  sequence_id?: string;
  team_id: string;
  date: string;
  title: string;
  starttime: string;
  endtime: string;
  description: string;
  deleted: boolean;
  created: string;
  updated: string;
  created_by: string;
  modified_by: string;
  color?: string;
  color_rgb?: string;
  color_is_dark?: boolean;
  startDateTime?: Date;
  endDateTime?: Date;
  position?: number;
  canEdit?: boolean;
  canDelete?: boolean;
  loading?: boolean;
}

export interface EnhancedEventModel extends EventModel {
  location: LocationModel;
  department: DepartmentModel;
  team: TeamModel;
}

export enum EventType {
  OCCURRENCE = 'occurrence',
  SEQUENCE = 'sequence',
}
