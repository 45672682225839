import { Injectable } from '@angular/core';
import { arrayOf, normalize } from 'normalizr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ArrayStrategy, objectToSearchParams } from '../../../api/api.helper';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction as Action } from '../../interfaces';
import { assignSchemaEntity } from '../../shared/assign';
import { reformatEntityResponse, reformatListResponse } from '../../shared/entity.helper';
import { EventSchema } from '../../shared/schemas';

export interface EventsLoadRequest {
  minDate?: string;
  maxDate?: string;
  departmentId?: string | string[];
}

@Injectable()
export class EventApi {
  private endpoint = 'events/';

  public constructor(private gateway: ApiGateway) {}

  public load(requestData: EventsLoadRequest, dispatchStart?: Action): Observable<any> {
    const search = objectToSearchParams(requestData, ArrayStrategy.MULTIPLE_PARAMS);

    return this.gateway
      .get(
        this.endpoint,
        {
          params: search,
        },
        dispatchStart,
      )
      .pipe(
        map((res) => reformatListResponse('Event', res)),
        map((data) => normalize(data, arrayOf(EventSchema), { assignEntity: assignSchemaEntity })),
      );
  }

  public add(eventData, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint, eventData, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Event', res)),
      map((data) => normalize(data, EventSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public update(id, data, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + id, data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Event', res)),
      map((data) => normalize(data, EventSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public fetch(id, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(this.endpoint + id, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Event', res)),
      map((data) => normalize(data, EventSchema, { assignEntity: assignSchemaEntity })),
    );
  }

  public fetchSequence(id, dispatchStart?: Action): Observable<any> {
    return this.gateway.get(this.endpoint + 'sequence/' + id, undefined, dispatchStart).pipe(
      map((res) => reformatListResponse('Event', res)),
      map((data) => normalize(data, arrayOf(EventSchema), { assignEntity: assignSchemaEntity })),
    );
  }

  public remove(id, dispatchStart?: Action): Observable<any> {
    return this.gateway.delete(this.endpoint + id, undefined, dispatchStart);
  }

  public removeSequence(id, dispatchStart?: Action): Observable<any> {
    return this.gateway.delete(this.endpoint + id + '/sequence', undefined, dispatchStart);
  }

  public addSequence(eventData, dispatchStart?: Action): Observable<any> {
    return this.gateway.post(this.endpoint + 'sequence', eventData, undefined, dispatchStart);
  }

  public updateSequence(id, data, dispatchStart?: Action): Observable<any> {
    return this.gateway.put(this.endpoint + id + '/sequence', data, undefined, dispatchStart).pipe(
      map((res) => reformatEntityResponse('Event', res)),
      map((data) => normalize(data, EventSchema, { assignEntity: assignSchemaEntity })),
    );
  }
}
