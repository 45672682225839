import { UnsafeAction as Action } from '../../interfaces';

const ACTION_PREFIX = '[Event]';

export const eventActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,

  FETCH: `${ACTION_PREFIX} Fetch`,
  FETCH_SUCCESS: `${ACTION_PREFIX} Fetch Success`,
  FETCH_FAILED: `${ACTION_PREFIX} Fetch Failed`,

  REMOVE: `${ACTION_PREFIX} Remove`,
  REMOVE_SUCCESS: `${ACTION_PREFIX} Remove Success`,
  REMOVE_FAILED: `${ACTION_PREFIX} Remove Failed`,
};

export class EventAction {
  static load(data): Action {
    return {
      type: eventActionType.LOAD,
      payload: data,
    };
  }

  static loadSuccess(events): Action {
    return {
      type: eventActionType.LOAD_SUCCESS,
      payload: events,
    };
  }

  static loadFailed(err): Action {
    return {
      type: eventActionType.LOAD_FAILED,
      payload: err,
    };
  }

  static add(correctionData): Action {
    return {
      type: eventActionType.ADD,
      payload: correctionData,
    };
  }

  static addSuccess(correctionResponse): Action {
    return {
      type: eventActionType.ADD_SUCCESS,
      payload: correctionResponse,
    };
  }

  static addFailed(err): Action {
    return {
      type: eventActionType.ADD_FAILED,
      payload: err,
    };
  }

  static update(eventData, id, loadingId): Action {
    return {
      type: eventActionType.UPDATE,
      payload: { eventData, id, loadingId },
    };
  }

  static updateSuccess(eventResponse, loadingId): Action {
    return {
      type: eventActionType.UPDATE_SUCCESS,
      payload: { ...eventResponse, loadingId },
    };
  }

  static fetch(id: any) {
    return {
      type: eventActionType.FETCH,
      payload: id,
    };
  }

  static fetchSuccess(response) {
    return {
      type: eventActionType.FETCH_SUCCESS,
      payload: response,
    };
  }

  static fetchFailed(id: string, err) {
    return {
      type: eventActionType.FETCH_FAILED,
      payload: {
        id,
        err,
      },
    };
  }

  static remove(id: string) {
    return {
      type: eventActionType.REMOVE,
      payload: id,
    };
  }

  static removeSuccess(id: string, departmentId: string, sequence: boolean) {
    return {
      type: eventActionType.REMOVE_SUCCESS,
      payload: {
        id,
        departmentId,
        sequence,
      },
    };
  }

  static removeFailed(id: string, err) {
    return {
      type: eventActionType.REMOVE_FAILED,
      payload: {
        id,
        err,
      },
    };
  }
}
